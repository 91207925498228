//ブラウザ判定
//現在の判定対象は「IE」、「Safari(Mac)」。それぞれ特定のクラスが付与される。

uaChecker();

function uaChecker() {
  let userAgent = window.navigator.userAgent.toUpperCase();
  let html = document.getElementsByTagName('html');
  const condIE = userAgent.indexOf('MSIE') === -1 && userAgent.indexOf('TRIDENT') === -1;
  const condMac = userAgent.indexOf('MACINTOSH') === -1;
  const condSafari = userAgent.indexOf("SAFARI") >= 0 && userAgent.indexOf("CHROME") === -1;


  /////////////////////////////////////////////////////
  //IE判定(EdgeのIEモードでは動かない可能性がある)
  /////////////////////////////////////////////////////
  if (condIE) {
    // IE以外のブラウザでアクセス
    // console.log('OK');
  } else {
    // IEでアクセス
    // console.log("IEだ！");
    html[0].classList.add("is-IE");
  }


  /////////////////////////////////////////////////////
  //Safari判定
  /////////////////////////////////////////////////////
  if (condMac) {
    // Mac以外のブラウザでアクセス
    // console.log('Macではない');
  } else {
    //Macでアクセス
    // console.log('Macです');

    //Safari判定
    if (condSafari) {
      // console.log('Safariです');
      html[0].classList.add("is-Safari");
    } else {
      // console.log('Macのchromeです');
    }
  }
}